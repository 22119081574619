import { addDays, isValid } from 'date-fns';
import { useState, useEffect } from 'react';

const COUNTER_COOKIE = 'counterStartDate';

const useCounterStartDate = (counterDays?: number) => {
  const [counterDate, setCounterDate] = useState<Date | null>(null);
  const [countdownIsHidden, setCountdownIsHidden] = useState(false);

  const hideCountdown = () => {
    localStorage.setItem(COUNTER_COOKIE, 'false');
    setCounterDate(null);
    setCountdownIsHidden(true);
  };

  useEffect(() => {
    const cookieDate = localStorage.getItem(COUNTER_COOKIE);

    if (!cookieDate) {
      const newStartDate = new Date();
      localStorage.setItem(COUNTER_COOKIE, newStartDate.toISOString());
      if (counterDays) {
        setCounterDate(addDays(newStartDate, counterDays));
      }
      return;
    }

    if (cookieDate === 'false') {
      hideCountdown();
      return;
    }

    const startDate = new Date(cookieDate);

    if (!isValid(startDate)) {
      hideCountdown();
      return;
    }

    if (counterDays) {
      setCounterDate(addDays(startDate, counterDays));
    }
  }, [counterDays]);

  return { counterDate, countdownIsHidden, hideCountdown };
};

export { useCounterStartDate };
